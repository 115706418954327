import React from 'react'
import * as styles from './Typography.css'
import classNames from 'classnames'

type ComponentMap = {
  p: HTMLParagraphElement
  h1: HTMLHeadingElement
  h2: HTMLHeadingElement
  h3: HTMLHeadingElement
  h4: HTMLHeadingElement
  h5: HTMLHeadingElement
  h6: HTMLHeadingElement
  strong: HTMLElement
  em: HTMLElement
  mark: HTMLElement
  small: HTMLElement
  del: HTMLElement
  ins: HTMLElement
  sub: HTMLElement
  sup: HTMLElement
  blockquote: HTMLElement
  q: HTMLQuoteElement
  abbr: HTMLElement
  cite: HTMLElement
  dfn: HTMLElement
  address: HTMLElement
  bdo: HTMLElement
  code: HTMLElement
  pre: HTMLPreElement
  var: HTMLElement
  kbd: HTMLElement
  samp: HTMLElement
  time: HTMLElement
  article: HTMLElement
  figcaption: HTMLElement
  figure: HTMLElement
  details: HTMLElement
  summary: HTMLElement
  span: HTMLSpanElement
}

type Component = keyof ComponentMap

type TypographyProps<T extends Component> = {
  component?: T
  weight?: 'light' | 'regular' | 'semibold' | 'bold' | 'extrabold'
  type?: 'micro' | 'small' | 'medium' | 'large'
  variant?: '1' | '2' | '3'
  children: React.ReactNode
} & React.HTMLAttributes<ComponentMap[T]>

const Typography: React.FC<TypographyProps<Component>> = ({
  component = 'p',
  weight = 'regular',
  type = 'medium',
  variant = '2',
  children,
  ...props
}) => {
  const className = classNames({
    [styles.WeightLight]: weight === 'light',
    [styles.WeightRegular]: weight === 'regular',
    [styles.WeightSemiBold]: weight === 'semibold',
    [styles.WeightBold]: weight === 'bold',
    [styles.WeightExtraBold]: weight === 'extrabold',
    [styles.Micro1]: type === 'micro' && variant === '1',
    [styles.Micro2]: type === 'micro' && variant === '2',
    [styles.Small1]: type === 'small' && variant === '1',
    [styles.Small2]: type === 'small' && variant === '2',
    [styles.Small3]: type === 'small' && variant === '3',
    [styles.Medium1]: type === 'medium' && variant === '1',
    [styles.Medium2]: type === 'medium' && variant === '2',
    [styles.Medium3]: type === 'medium' && variant === '3',
    [styles.Large1]: type === 'large' && variant === '1',
    [styles.Large2]: type === 'large' && variant === '2',
    [styles.Large3]: type === 'large' && variant === '3',
    [styles.Color]: true,
  })

  return React.createElement(
    component,
    { ...props, className: `${className} ${props.className}` },
    children
  )
}

export default Typography
