/* eslint-disable max-len */

'use client';

import Error from 'components/new-components/Error/Error';
import Error404Logo from 'assets/logos/404.svg';

export default function NotFound({ error }: { error: Error & { digest?: string }}) {
  return (
    <Error
      error={error}
      title="A página que você procura não foi encontrada"
      message="Ela pode não estar mais disponível ou ter tido o seu endereço alterado."
      caption="Enquanto tentamos descobrir o que aconteceu, você gostaria de procurar algum evento?"
      image={<Error404Logo width="100%" />}
    />
  );
}
